import { FC } from 'react';
import { AuthMakeOfferModal } from './authMakeOffer';
import { AnonMakeOfferModal } from './anonMakeOffer';
import { userIsLoggedIn } from '@/src/state/user/selectors';
import { useAppSelector } from '@/src/store';
import { MakeOffer } from './makeOffer';
import { BooleanFlag, useBooleanFeatureFlag } from '@/src/hooks/featureFlags';

export const MakeOfferModal: FC = () => {
  const isLoggedIn = useAppSelector(userIsLoggedIn);

  const makeOfferOn = useBooleanFeatureFlag(BooleanFlag.MakeOffer);

  if (isLoggedIn && makeOfferOn) return <MakeOffer />;

  return isLoggedIn ? <AuthMakeOfferModal /> : <AnonMakeOfferModal />;
};
