import { borderRadius, colors, spacing } from '@/src/design/designConstants';
import { Button, Chip, Stack, Typography } from '@mui/material';
import { closeModal, GlobalModalTypes, openModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { getCurrentThread } from '@/src/messages/selectors';
import { MessageChat } from '@/src/messages/messageChat';
import { deselectMessage } from '@/src/messages/slice';
import Image from 'next/image';
import { IMAGE_URLS } from '@/src/constants/imageUrls';
import { useLocalizer } from '@/src/localization';
import { useEffect, useRef, useState } from 'react';
import { useFetchCurrentMessageListing } from '@/src/messages/useFetchCurrentMessageListing';
import { useFullStory } from '@/src/hooks/useFullStory/useFullStory';
import { CustomEvents } from '@/src/hooks/useFullStory/customEvents';
import { FeatureGuard } from '@/src/guards/featureGuard';
import routes from '@/src/routes';
import { FEATURE_NAME } from '@/src/hooks/useFeatures';
import { useResizeObserver } from '@/src/hooks/useResizeObserver';

export const MessagesChatMobile = () => {
  const dispatch = useAppDispatch();
  const currentThread = useAppSelector(getCurrentThread);
  const photo = currentThread.listing_info.photo?.small_url || IMAGE_URLS.NULL_STATE_PHOTO_SMALL;
  const loc = useLocalizer();
  const messageChatTitleRef = useRef<HTMLDivElement>(null);
  const [chatOffsetHeight, setChatOffsetHeight] = useState(0);
  const { listing } = useFetchCurrentMessageListing();
  const { fullStoryAndGTMEvent } = useFullStory();
  const { setObserver } = useResizeObserver();

  useEffect(() => {
    if (messageChatTitleRef.current) {
      setObserver(messageChatTitleRef, () => {
        if (messageChatTitleRef.current) {
          setChatOffsetHeight(messageChatTitleRef.current.offsetHeight);
        }
      });
    }
  }, [messageChatTitleRef.current]);

  return (
    <Stack height={'100%'} data-testid="mobile-messages-chat">
      <Stack
        borderBottom={`2px solid ${colors.corpGray['100']}`}
        width="fill-available"
        alignContent={'center'}
        position={'fixed'}
        bgcolor={colors.surface.white}
        ref={messageChatTitleRef}
      >
        <Stack alignItems="center" direction={'row'} spacing={spacing.xl}>
          <Button sx={{ p: spacing.xl }} color="secondary" variant="text" onClick={() => dispatch(deselectMessage())}>
            <FAIcon icon={icons.chevronLeft} />
          </Button>

          <Typography sx={{ ['&.MuiTypography-root']: { margin: '0px' } }} variant="subtitle3">
            Messages
          </Typography>
        </Stack>
        <Stack
          borderTop={`2px solid ${colors.corpGray['100']}`}
          direction="row"
          height="120px"
          bgcolor={colors.corpGray['50']}
          p={spacing.xl}
          spacing={spacing.lg}
        >
          <Stack maxWidth="25%">
            <Image
              alt={`listing-image-${currentThread.listing_info?.make_model}`}
              src={photo}
              quality={100}
              objectFit="cover"
              height={80}
              width={80}
              style={{ borderRadius: borderRadius.rounded }}
            />
          </Stack>

          <Stack flex={1} width="75%" spacing={spacing.md} justifyContent={'center'}>
            <Chip
              sx={{
                width: 'fit-content',
                border: `1px solid ${colors.magenta[200]}`,
                backgroundColor: '#FFFAFD',
                color: colors.magenta[500],
                fontSize: '11px'
              }}
              label={currentThread.receiver_alias}
              size="small"
            />

            <Typography variant="caption" noWrap>
              {currentThread.listing_info.make_model}
            </Typography>

            {listing?.current_user_is_owner ? (
              <>
                <FeatureGuard featureName={FEATURE_NAME.LISTINGS}>
                  <Button
                    onClick={() => dispatch(closeModal())}
                    size="small"
                    sx={{ width: 'fit-content' }}
                    color="info"
                    variant="outlined"
                    href={routes.listingEdit(listing)}
                  >
                    <Stack spacing={spacing.md} direction="row" alignItems={'center'}>
                      <FAIcon icon={icons.pencil} />
                      <Typography variant="button14AllCaps">{loc.EditListingMain.EditListing}</Typography>
                    </Stack>
                  </Button>
                </FeatureGuard>
                <FeatureGuard featureName={FEATURE_NAME.EMS}>
                  <Button
                    onClick={() => dispatch(closeModal())}
                    size="small"
                    sx={{ width: 'fit-content' }}
                    color="info"
                    variant="outlined"
                    href={routes.equipmentEditFromListing(listing)}
                  >
                    <Stack spacing={spacing.md} direction="row" alignItems={'center'}>
                      <FAIcon icon={icons.pencil} />
                      <Typography variant="button14AllCaps">{loc.EditListingMain.EditListing}</Typography>
                    </Stack>
                  </Button>
                </FeatureGuard>
              </>
            ) : (
              listing && (
                <Button
                  onClick={() => {
                    fullStoryAndGTMEvent(CustomEvents.listing.CTAClickFromMessagingSummary, {
                      type: 'MakeOffer'
                    });
                    dispatch(
                      openModal({
                        modalType: GlobalModalTypes.MAKE_OFFER,
                        modalProps: {
                          makeOffer: { listing: listing.toSerializable() }
                        }
                      })
                    );
                  }}
                  sx={{ width: '50%' }}
                  size="small"
                  variant="contained"
                  color="primary"
                  id="messages-CTA-make-offer-button"
                >
                  {loc.Listing.MakeOffer.toUpperCase()}
                </Button>
              )
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack height="100%" pt={`${chatOffsetHeight}px`}>
        <MessageChat />
      </Stack>
    </Stack>
  );
};
