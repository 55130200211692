import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export enum OfferTabs {
  SELLING = 0,
  BUYING = 1
}

export interface OffersState {
  // TODO: define the shape of offers in by id
  byId: Record<string, unknown>;
  currentOfferId: string;
  loading: boolean;
  error: boolean;
  initialized: boolean;
  currentTab: OfferTabs;
}

export const initialState: OffersState = {
  byId: {},
  currentOfferId: '',
  loading: false,
  error: false,
  initialized: false,
  currentTab: OfferTabs.SELLING
};

export const slice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setCurrentTab: (state, action: PayloadAction<OfferTabs>) => {
      state.currentTab = action.payload;
    }
  }
});

export const { setCurrentTab } = slice.actions;

export default slice.reducer;
