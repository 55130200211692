import axios from 'axios';
import { AppDispatch, AppState } from '../store';
import { MOOV_API_URL } from '../constants';
import { AddressSerializable } from '../common/lib';

export interface MakeOfferPayload {
  offer_amount: number;
  destination_address: AddressSerializable;
  offer_note: string;
  offer_subject_to: boolean;
  offer_subject_to_note: string;
  required_by: string;
  terms_and_conditions: boolean;
}

export function createOffer({ body, listingKey }: { body: MakeOfferPayload; listingKey: string }) {
  return async (_dispatch: AppDispatch, _getState: () => AppState) => {
    return axios
      .post<void>(
        `${MOOV_API_URL}/auth/offers/listing/${listingKey}`,
        { ...body },
        {
          headers: { 'Content-Type': 'application/vnd.api+json' },
          withCredentials: true
        }
      )
      .then(() => {
        // TODO: ENG-2362 put offer in store and show "Pending Offer" on listing page
      });
  };
}
