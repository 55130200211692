import { colors, spacing } from '@/src/design/designConstants';
import { Drawer, Modal, Stack, Typography } from '@mui/material';
import { closeModal } from '@/src/state/globalModal/slice';
import { useAppDispatch, useAppSelector } from '@/src/store';
import { FAIcon } from '@/src/design/bits/fAIcon/fAIcon';
import { icons } from '@/src/design/bits/fAIcon/icons';
import { MobileMessages } from '@/src/messages/main/mobile';
import { getCurrentThread } from '@/src/messages/selectors';
import { MessagesChatMobile } from '@/src/messages/main/mobile/mobileMessageChat';

export const MobileMessageCenter = () => {
  const dispatch = useAppDispatch();
  const currentThread = useAppSelector(getCurrentThread);
  return (
    <>
      <Modal open={true}>
        <Stack data-testid="messaging-center-thread-list-mobile" height={'100%'} width="100%" bgcolor={colors.surface.white}>
          <Stack
            borderBottom={`2px solid ${colors.corpGray['100']}`}
            direction="row"
            alignItems={'center'}
            justifyContent={'space-between'}
            pl={spacing.xl}
          >
            {/* TODO: translations */}
            <Typography variant="subtitle3">Messages</Typography>
            <Stack p={spacing.xl} justifyContent={'center'} onClick={() => dispatch(closeModal())}>
              <FAIcon icon={icons.xMark} />
            </Stack>
          </Stack>
          <MobileMessages />
        </Stack>
      </Modal>
      <Drawer anchor="right" sx={{ zIndex: 10000 }} open={Boolean(currentThread.key)} data-testid="mobile-messages-chat-drawer">
        <MessagesChatMobile />
      </Drawer>
    </>
  );
};
