var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"Ji3aeaZnjFy4bGysISUS8"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

import * as Sentry from '@sentry/nextjs';
import SentryFullStory from '@sentry/fullstory';
import * as FullStory from '@fullstory/browser';

/**
 * This file configures the initialization of Sentry on the browser.
 * The config you add here will be used whenever a page is visited.
 * https://docs.sentry.io/platforms/javascript/guides/nextjs/
 *
 * Note: if you want to override the automatic release value, do not set a
 * `release` value here - use the environment variable `SENTRY_RELEASE`, so
 * that it will also get attached to your source maps
 */
Sentry.init({
  enabled: ['production', 'stage'].includes(process.env.NEXT_PUBLIC_MOOV_CO_WEB_ENV),
  dsn: process.env.NEXT_PUBLIC_SENTRY_DSN_CLIENT,
  environment: process.env.NEXT_PUBLIC_MOOV_CO_WEB_ENV,
  tracesSampleRate: 0.2,
  integrations: [new SentryFullStory(process.env.NEXT_PUBLIC_SENTRY_ORG_SLUG, { client: FullStory })],
  debug: process.env.NEXT_PUBLIC_MOOV_CO_WEB_ENV !== 'production' // debug in all env's except production
  // beforeSend(event) {
  //   if (event.message === 'Request failed with status code 403') {
  //     return null;
  //   }
  //   return event;
  // }
});
