import { icon, solid } from '@fortawesome/fontawesome-svg-core/import.macro';

/**
 * Add standard icon definitions here to enable use in the `FAIcon` component.
 *
 * Please DO NOT use this const directly to pass an icon to `FAIcon`.
 * Use the combined `icons` const instead.
 */
const standardIconDefinitions = {
  calendarDays: icon({ name: 'calendar-days' }),
  infoCircle: icon({ name: 'info-circle' }),
  pencil: icon({ name: 'pencil' }),
  questionCircle: icon({ name: 'question-circle' }),
  search: icon({ name: 'search' }),
  chevronLeft: icon({ name: 'chevron-left' }),
  chevronsLeft: icon({ name: 'chevrons-left' }),
  chevronRight: icon({ name: 'chevron-right' }),
  chevronDown: icon({ name: 'chevron-down', style: 'solid', family: 'sharp' }),
  chevronUp: icon({ name: 'chevron-up', style: 'solid', family: 'sharp' }),
  trashCan: icon({ name: 'trash-can' }),
  rocket: icon({ name: 'rocket', style: 'solid', family: 'duotone' }),
  xMark: icon({ name: 'xmark' }),
  check: icon({ name: 'check' }),
  lock: icon({ name: 'lock' }),
  dollarSign: icon({ name: 'dollar-sign' }),
  circlePlus: icon({ name: 'circle-plus' }),
  circleCheck: icon({ name: 'circle-check' }),
  circleExclamation: icon({ name: 'circle-exclamation' }),
  circle: icon({ name: 'circle' }),
  truck: icon({ name: 'truck' }),
  shieldCheck: icon({ name: 'shield-check' }),
  badgeCheck: icon({ name: 'badge-check' }),
  userSolid: solid('user'),
  globe: icon({ name: 'globe', style: 'regular' }),
  image: icon({ name: 'image' }),
  fileArrowDown: icon({ name: 'file-arrow-down', family: 'classic', style: 'regular' }),
  columns3: icon({ name: 'columns-3', family: 'classic', style: 'regular' }),
  sliders: icon({ name: 'sliders', family: 'classic', style: 'regular' }),
  weChat: icon({ name: 'weixin', style: 'brands' }),
  xTwitter: icon({ name: 'x-twitter', style: 'brands' }),
  linkedInIn: icon({ name: 'linkedin-in', style: 'brands' }),
  facebookF: icon({ name: 'facebook-f', style: 'brands' }),
  ban: icon({ name: 'ban', family: 'classic', style: 'regular' }),
  file: icon({ name: 'file', family: 'classic', style: 'regular' }),
  solidFile: icon({ name: 'file', family: 'classic', style: 'solid' }),
  camera: icon({ name: 'camera', family: 'classic', style: 'solid' }),
  message: icon({ name: 'message', family: 'classic', style: 'solid' }),
  briefCaseFilled: icon({ name: 'briefcase-blank', family: 'classic', style: 'solid' }),
  messageOutlined: icon({ name: 'message', family: 'classic', style: 'regular' }),
  messageFilled: icon({ name: 'message', family: 'classic', style: 'solid' }),
  noteSticky: icon({ name: 'note-sticky', family: 'classic', style: 'regular' })
} as const;

/**
 * Add new custom icon classes here to enable use in the `FAIcon` component.
 * Our custom icons can be found here: https://fontawesome.com/kits/4479fb7f10/icons
 *
 * Please DO NOT use this const directly to pass an icon to `FAIcon`.
 * Use the combined `icons` const instead.
 */
export const customIconNames = {
  soldSign: 'sold-sign',
  filePayment: 'file-payment',
  inventoryBox: 'inventory-box',
  truckDelivered: 'truck-delivered',
  listingSign: 'listing-sign',
  questionAlertOutline: 'question-alert-outline',
  questionAlertSolid: 'question-alert-solid',
  equipment: 'equipment',
  refurbishment: 'refurbishment'
} as const;

/**
 * Use this in conjunction with the `FAIcon` component to display icons.
 */
export const icons = {
  ...standardIconDefinitions,
  ...customIconNames
} as const;
