import { Route, Slug } from './entities/common';
import { EquipmentSerializable, isEquipment } from './equipment/types';
import { Listing, ListingSummary } from './entities/listing';
import { User } from './entities/user/types';
import { isEmpty } from './utils';
import { Listing2 } from './listings/types';
import { SearchParams } from './marketplace/search/types';
import { filterOutEmptyFilterValues } from './marketplace/search/helpers';

export enum InsightsPages {
  marketTrends2022 = 'Market-Trends-2022'
}

export enum ROUTE_PATHNAMES {
  _error = '/_error',
  root = '/',
  notFound = '/404',
  about = '/about',
  blogPost = '/blog/[slug]/[blog_id]',
  blog = '/blog/page/[page_num]',
  buy = '/buy',
  buyOrders = '/buy/orders',
  buyPicks = '/buy/picks',
  requests = '/buy/requests',
  buySuggested = '/buy/suggested',
  careers = '/careers',
  magiq = '/cj/[magiq_key]',
  magiqExpired = '/cj/[magiq_key]/expired',
  magiqOpportunity = '/cj/opp/[opportunity_key]',
  companyProfile = '/company_profile',
  dashboard = '/dashboard',
  marketTrends = '/insights/Market-Trends-2022',
  marketplace = '/marketplace',
  category = '/marketplace/[category_slug]',
  make = '/marketplace/[category_slug]/[make_slug]',
  model = '/marketplace/[category_slug]/[make_slug]/[model_slug]',
  listing = '/marketplace/[category_slug]/[make_slug]/[model_slug]/[listing_key]',
  listingEdit = '/marketplace/[category_slug]/[make_slug]/[model_slug]/[listing_key]/edit',
  categoryAll = '/marketplace/all-equipment',
  makeAll = '/marketplace/all-equipment/[make_slug]',
  suggested = '/marketplace/suggested',
  news = '/news/page/[page_num',
  policyPrivacy = '/policies/privacy',
  policyTerms = '/policies/terms',
  profile = '/profile',
  register = '/register',
  registerCompany = '/register/company',
  remarketing = '/remarketing',
  resetPassword = '/reset_password',
  sandbox = '/sandbox',
  search = '/search',
  sell = '/sell',
  createListing = '/sell/create-listing',
  sellListings = '/sell/listings',
  sellEquipment = '/sell/equipment',
  equipmentEdit = '/sell/equipment/[equipment_id]/edit',
  equipmentMaxit = '/sell/equipment/[equipment_id]/maxit',
  sellSold = '/sell/sold',
  services = '/services',
  settlement = '/settlements',
  settlements = '/settlements/[settlement_key]',
  unsubscribe = '/unsubscribe',
  verifyEmail = '/verify_email',
  exclusives = '/exclusives/[remarketing_slug]',
  exclusivesAll = '/exclusives',
  messages = '/messages',
  offers = '/offers'
}

/**
 * Reserved route: `/**\/*` where `**` is any locale, partial or full. EGs: `/fr` and `/zh-cn`.
 */
class Routes {
  /**
   *
   */
  private queryParamsToString(queryObj: Record<string, string | number | boolean | (number | string)[]>): Route {
    let queryString = '';

    for (const [key, value] of Object.entries(queryObj)) {
      if (isEmpty(value)) {
        continue;
      }

      if (queryString.length > 0) {
        queryString += '&';
      }

      if (Array.isArray(value)) {
        queryString += value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join('&');
      } else {
        queryString += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
      }
    }
    return queryString;
  }

  /**
   * Public 404 route.
   */
  public notFound(): Route {
    return '/404';
  }

  /**
   * Public route.
   */
  public about(): Route {
    return '/about';
  }

  /**
   * Public route.
   */
  public blog(page = '1'): Route {
    return '/blog/page/' + page;
  }

  public insights(page: InsightsPages): Route {
    return `/insights/${page}`;
  }

  /**
   * Public route.
   */
  public blogPost(blogSlug: Slug, blogId: string): Route {
    return `/blog/${blogSlug}/${blogId}`;
  }

  /**
   * Public and protected route.
   */
  public buy(anchor?: string): Route {
    let route = '/buy';
    if (anchor !== undefined) {
      route += `#${anchor}`;
    }
    return route;
  }

  /**
   * Protected route.
   */
  public inReview(): Route {
    return `${this.buy()}/picks`;
  }

  /**
   * Protected route.
   */
  public opportunities(): Route {
    return `${this.buy()}/suggested`;
  }

  /**
   * Protected route.
   */
  public orders(): Route {
    return `${this.buy()}/orders`;
  }

  /**
   * Public route.
   */
  public careers(): Route {
    return '/careers';
  }

  /**
   * Public route.
   */
  public category(categorySlug: Slug): Route {
    return `${this.marketplace()}/${categorySlug}`;
  }

  /**
   * Public route.
   */
  public categoryAll(): Route {
    return `${this.marketplace()}/all-equipment`;
  }

  /**
   * Protected route.
   */
  public dashboard(): Route {
    return '/dashboard';
  }

  /**
   * Public route.
   */
  public policyCandidatePrivacy(): Route {
    return '/policies/ccpa_cpra';
  }

  /**
   * Public route.
   */
  public companyProfile(): Route {
    return '/company_profile';
  }

  /**
   * Public route.
   * @deprecated This page is NYI.
   */
  public contact(): Route {
    return '/contact';
  }

  /**
   * Public route.
   */
  public exclusives(remarketingSlug: string): Route {
    return `/exclusives/${remarketingSlug}`;
  }

  /**
   * Public route.
   */
  public exclusivesAll(): Route {
    return '/exclusives';
  }

  /**
   * Protected route.
   */
  public magiqExpired(magiqKey: string): Route {
    return `/cj/${magiqKey}/expired`;
  }

  /**
   *
   */
  public home(user: User | null | undefined, userIsLoggedIn: (value: User | null | undefined) => value is User): Route {
    return userIsLoggedIn(user) ? routes.dashboard() : routes.root();
  }

  /**
   * Public route.
   */
  public listing(obj: Listing | ListingSummary | Listing2 | EquipmentSerializable): Route {
    const { category, make, model } = obj;
    const key = isEquipment(obj) ? obj.listing_key : obj.key;

    return `${this.model(category.slug, make.slug, model.slug)}/${key}`;
  }

  /**
   * Protected route.
   */
  public listingEdit(listing: Listing | ListingSummary | Listing2): Route {
    return `${this.listing(listing)}/edit`;
  }

  /**
   * Protected route.
   */
  public listings(): Route {
    return '/listings';
  }

  /**
   * Protected route.
   */
  public messages(): Route {
    return '/messages';
  }

  /**
   * Protected route.
   */
  public offers(): Route {
    return '/offers';
  }

  /**
   * Public route.
   */
  public make(categorySlug: Slug, makeSlug: Slug): Route {
    return `${this.category(categorySlug)}/${makeSlug}`;
  }

  /**
   * Public route.
   */
  public makeAll(makeSlug: Slug): Route {
    return `${this.categoryAll()}/${makeSlug}`;
  }

  /**
   * Public route.
   */
  public marketplace(): Route {
    return '/marketplace';
  }

  /**
   * Public route.
   */
  public model(categorySlug: Slug, makeSlug: Slug, modelSlug: Slug): Route {
    return `${this.make(categorySlug, makeSlug)}/${modelSlug}`;
  }

  /**
   * Public route.
   */
  public news(page = '1'): Route {
    return '/news/page/' + page;
  }

  /**
   * Public route.
   */
  public policyPrivacy(): Route {
    return '/policies/privacy';
  }

  /**
   * Public route.
   */
  public policyTerms(): Route {
    return '/policies/terms';
  }

  /**
   * Protected route.
   */
  public profile(): Route {
    return '/profile';
  }

  /**
   * Public route.
   */
  public register(): Route {
    return '/register';
  }

  /**
   * Protected route.
   */
  public registerCompany(): Route {
    return `${this.register()}/company`;
  }

  /**
   * Public route.
   */
  public remarketing(): Route {
    return '/remarketing';
  }

  /**
   * Protected route.
   */
  public resetPassword(): Route {
    return '/reset_password';
  }

  /**
   * Public route.
   * @deprecated This page is NYI.
   */
  public resources(): Route {
    return '/resources';
  }

  /**
   * Public route.
   */
  public root(): Route {
    return '/';
  }

  /**
   * Use this function with an `href` prop or as the only argument to `router.push`.
   * This is not intended to be used as a `pathname` option with `router.push`.
   *
   * Examples of proper use:
   * - `<Link href={routes.search({ ... })} />`
   * - `router.push(routes.search({ ... }))`
   *
   * Examples of improper use:
   * - `router.push({ pathname: routes.search() }, query: { ... })`
   */
  public search(params?: SearchParams): Route {
    let route = '/search';

    if (params) {
      const nonEmptyValues = filterOutEmptyFilterValues(params);

      if (!isEmpty(nonEmptyValues)) {
        route += `?${this.queryParamsToString(nonEmptyValues)}`;
      }
    }

    return route;
  }

  /**
   * Public and protected route.
   */
  public sell(params?: { model_id: number | string } | Record<string, never>, anchor?: string): Route {
    let route = '/sell';
    if (anchor !== undefined) {
      route += `#${anchor}`;
    }
    if (params !== undefined && !isEmpty(params)) {
      return `${route}?${this.queryParamsToString(params)}`;
    }
    return route;
  }

  /**
   * Protected route.
   */
  public equipment(): Route {
    return `${this.sell()}/equipment`;
  }

  /**
   * Protected route.
   */
  public equipmentEdit(equipment: EquipmentSerializable): Route {
    return `${this.equipment()}/${equipment.id}/edit`;
  }

  public equipmentEditFromListing(listing: Listing2): Route {
    return `${this.equipment()}/${listing.equipment_id}/edit`;
  }

  /**
   * Protected route.
   */
  public createListing(): Route {
    return `${this.sell()}/create-listing`;
  }

  /**
   * Public and protected route.
   */
  public myListings(): Route {
    return `${this.sell()}/listings?tab=listings`;
  }

  /**
   * Public and protected route.
   */
  public sellListingsPending(): Route {
    return `${this.sell()}/listings?tab=pending`;
  }

  /**
   * Protected route.
   */
  public sellSold(): Route {
    return `${this.sell()}/sold`;
  }

  /**
   * Protected route.
   */
  public settlement(settlementKey: string): Route {
    return `${this.settlements()}/${settlementKey}`;
  }

  /**
   * Protected route.
   */
  public settlements(): Route {
    return '/settlements';
  }

  /**
   * Public route.
   */
  public services(anchor?: string): Route {
    let route = '/services';
    if (typeof anchor !== 'undefined') {
      route += `#${anchor}`;
    }
    return route;
  }

  /**
   * Protected route.
   */
  public suggested(): Route {
    return `${this.marketplace()}/suggested`;
  }

  /**
   * Public route.
   */
  public team(): Route {
    return '/team';
  }

  /**
   * Protected route.
   */
  public verifyEmail(): Route {
    return '/verify_email';
  }

  /**
   * Protected route.
   */
  public requests(): Route {
    return '/buy/requests';
  }
}

const routes = new Routes();

export default routes;
